<template>
  <spinner
    v-if="initialDataStatus === 'loading'"
    preset="large"
  />

  <template v-else-if="initialDataStatus === 'loaded'">
    <alert
      v-if="formSuccess"
      variant="success"
    >
      Your changes have been saved.
    </alert>

    <template v-else>
      <alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        class="js-form-error"
        dismissible
        @dismissed="formErrors = {}"
      >
        {{ formErrors.non_field_errors[0] }}
      </alert>

      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="username">Username</label>
          <input
            id="username"
            v-model="formFields.username"
            :class="{ 'is-invalid': formErrors.username !== undefined }"
            type="text"
            maxlength="150"
            required
          >
          <div
            v-if="formErrors.username !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.username[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email address</label>
          <input
            id="email"
            v-model="formFields.email"
            :class="{ 'is-invalid': formErrors.email !== undefined }"
            type="email"
            maxlength="254"
            required
          >
          <div
            v-if="formErrors.email !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.email[0] }}
          </div>
        </div>

        <div class="form-check">
          <input
            id="change_password"
            v-model="formFields.change_password"
            type="checkbox"
            class="form-check-input"
          >
          <label
            for="change_password"
            class="form-check-label"
          >
            I want to change my password
          </label>
        </div>

        <transition-slide mode="out-in">
          <div v-if="formFields.change_password">
            <alert>
              When you change your password, any other device you are logged in
              on will automatically be logged out.
            </alert>

            <div class="form-group">
              <label for="current_password">Current password</label>
              <input
                id="current_password"
                v-model="formFields.current_password"
                :class="{ 'is-invalid': formErrors.current_password !== undefined }"
                type="password"
                minlength="8"
                required
              >
              <div
                v-if="formErrors.current_password !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.current_password[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="new_password1">New password</label>
              <input
                id="new_password1"
                v-model="formFields.new_password1"
                :class="{ 'is-invalid': formErrors.new_password1 !== undefined }"
                type="password"
                minlength="8"
                required
                aria-describedby="new_password1_help"
              >
              <small
                id="new_password1_help"
                class="form-text"
              >
                Your password must contain at least 8 characters.
              </small>
              <div
                v-if="formErrors.new_password1 !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.new_password1[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="new_password2">New password confirmation</label>
              <input
                id="new_password2"
                v-model="formFields.new_password2"
                :class="{ 'is-invalid': formErrors.new_password2 !== undefined }"
                type="password"
                minlength="8"
                required
              >
              <div
                v-if="formErrors.new_password2 !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.new_password2[0] }}
              </div>
            </div>
          </div>
        </transition-slide>

        <div class="form-group">
          <label for="phone">Phone number (optional)</label>
          <input
            id="phone"
            v-model="formFields.phone"
            :class="{ 'is-invalid': formErrors.phone !== undefined }"
            type="tel"
            maxlength="30"
          >
          <div
            v-if="formErrors.phone !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.phone[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="biography">About me (optional)</label>
          <textarea
            id="biography"
            v-model="formFields.biography"
            :class="{ 'is-invalid': formErrors.biography !== undefined }"
          />
          <div
            v-if="formErrors.biography !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.biography[0] }}
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary"
          :disabled="formSubmitting"
        >
          <template v-if="formSubmitting">
            Saving
            <spinner />
          </template>

          <template v-else>
            Save
          </template>
        </button>
      </form>
    </template>
  </template>

  <template v-else-if="initialDataStatus === 'error'">
    <alert variant="danger">
      An error occurred loading your data. Please check your connection and try
      again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadInitialData"
    >
      Try Again
    </button>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TransitionSlide from '@/components/TransitionSlide.vue';

export default defineComponent({
  components: {
    TransitionSlide,
  },
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    formFields: {
      username: '',
      email: '',
      change_password: false,
      current_password: '',
      new_password1: '',
      new_password2: '',
      phone: '',
      biography: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_account/',
      });

      if (responseData.status === 200) {
        const initialData = responseData.body;

        this.formFields.username = initialData.username;
        this.formFields.email = initialData.email;
        this.formFields.phone = initialData.phone;
        this.formFields.biography = initialData.biography;

        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const json = JSON.parse(JSON.stringify(this.formFields));

      if (!this.formFields.change_password) {
        delete json.current_password;
        delete json.new_password1;
        delete json.new_password2;
      }

      const responseData = await this.api({
        url: 'users/settings_account/',
        method: 'POST',
        json,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.$store.commit('drfToken', responseData.body.drf_token);
        this.$store.commit('userData', responseData.body.user_data);
        this.formSuccess = true;
        this.$store.commit('userMenuProfileImageKeySuffixIncrement');
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style scoped>
  form {
    margin: 0 auto 1rem;
    width: 290px;
  }
</style>
