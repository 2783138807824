<template>
  <div class="horizontal-padding">
    <h1>Settings</h1>

    <nav
      class="navbar"
      aria-label="Settings"
    >
      <ul>
        <li>
          <router-link :to="{ name: 'settings_account' }">
            Account
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'settings_images' }">
            Profile Image & Cover Image
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'settings_notifications' }">
            Notifications
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'settings_third_party_accounts' }">
            Gaming & Social Media Accounts
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'settings_subscription' }">
            Subscription
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'settings_ipg' }">
            IPG
          </router-link>
        </li>
      </ul>
    </nav>

    <div>
      <settings-account v-if="activeTab === 'account'" />

      <settings-images v-else-if="activeTab === 'images'" />

      <settings-ipg v-else-if="activeTab === 'ipg'" />

      <settings-notifications v-else-if="activeTab === 'notifications'" />

      <settings-subscription v-else-if="activeTab === 'subscription'" />

      <settings-third-party-accounts v-else-if="activeTab === 'third_party_accounts'" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SettingsAccount from '@/components/users/SettingsAccount.vue';
import SettingsImages from '@/components/users/SettingsImages.vue';
import SettingsIpg from '@/components/users/SettingsIpg.vue';
import SettingsNotifications from '@/components/users/SettingsNotifications.vue';
import SettingsSubscription from '@/components/users/SettingsSubscription.vue';
import SettingsThirdPartyAccounts from '@/components/users/SettingsThirdPartyAccounts.vue';

export default defineComponent({
  components: {
    SettingsAccount,
    SettingsImages,
    SettingsIpg,
    SettingsNotifications,
    SettingsSubscription,
    SettingsThirdPartyAccounts,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
});
</script>
