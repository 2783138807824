
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    formFields: {
      post_comment: '',
      post_like: '',
      comment_reply: '',
      comment_like: '',
      team_join_request_received: '',
      team_join_invitation_accepted: '',
      team_join_invitation_declined: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_notifications/',
      });

      if (responseData.status === 200) {
        const initialData = responseData.body;

        this.formFields.post_comment = initialData.post_comment;
        this.formFields.post_like = initialData.post_like;
        this.formFields.comment_reply = initialData.comment_reply;
        this.formFields.comment_like = initialData.comment_like;
        this.formFields.team_join_request_received = initialData.team_join_request_received;
        this.formFields.team_join_invitation_accepted = initialData.team_join_invitation_accepted;
        this.formFields.team_join_invitation_declined = initialData.team_join_invitation_declined;

        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'users/settings_notifications/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.formSuccess = true;
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
