
import { defineComponent } from 'vue';
import TransitionSlide from '@/components/TransitionSlide.vue';

export default defineComponent({
  components: {
    TransitionSlide,
  },
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    formFields: {
      username: '',
      email: '',
      change_password: false,
      current_password: '',
      new_password1: '',
      new_password2: '',
      phone: '',
      biography: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_account/',
      });

      if (responseData.status === 200) {
        const initialData = responseData.body;

        this.formFields.username = initialData.username;
        this.formFields.email = initialData.email;
        this.formFields.phone = initialData.phone;
        this.formFields.biography = initialData.biography;

        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const json = JSON.parse(JSON.stringify(this.formFields));

      if (!this.formFields.change_password) {
        delete json.current_password;
        delete json.new_password1;
        delete json.new_password2;
      }

      const responseData = await this.api({
        url: 'users/settings_account/',
        method: 'POST',
        json,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.$store.commit('drfToken', responseData.body.drf_token);
        this.$store.commit('userData', responseData.body.user_data);
        this.formSuccess = true;
        this.$store.commit('userMenuProfileImageKeySuffixIncrement');
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
