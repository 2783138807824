<template>
  <spinner
    v-if="initialDataStatus === 'loading'"
    preset="large"
  />

  <template v-else-if="initialDataStatus === 'loaded'">
    <alert
      v-if="formSuccess"
      variant="success"
    >
      Your changes have been saved.
    </alert>

    <template v-else>
      <alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        class="js-form-error"
        dismissible
        @dismissed="formErrors = {}"
      >
        {{ formErrors.non_field_errors[0] }}
      </alert>

      <p class="text-center">
        Which of these events would you like to receive notifications for?
      </p>

      <form @submit.prevent="submitForm">
        <div class="form-check">
          <input
            id="post_comment"
            v-model="formFields.post_comment"
            type="checkbox"
            class="form-check-input"
            aria-describedby="post_comment_description"
          >
          <label
            for="post_comment"
            class="form-check-label"
          >
            Post comments
          </label>

          <small id="post_comment_description">
            Someone left a comment on a post you made
          </small>
        </div>

        <div class="form-check">
          <input
            id="post_like"
            v-model="formFields.post_like"
            type="checkbox"
            class="form-check-input"
            aria-describedby="post_like_description"
          >
          <label
            for="post_like"
            class="form-check-label"
          >
            Post likes
          </label>

          <small id="post_like_description">
            Someone liked a post you made
          </small>
        </div>

        <div class="form-check">
          <input
            id="comment_reply"
            v-model="formFields.comment_reply"
            type="checkbox"
            class="form-check-input"
            aria-describedby="comment_reply_description"
          >
          <label
            for="comment_reply"
            class="form-check-label"
          >
            Comment replies
          </label>

          <small id="comment_reply_description">
            Someone replied to a comment you made
          </small>
        </div>

        <div class="form-check">
          <input
            id="comment_like"
            v-model="formFields.comment_like"
            type="checkbox"
            class="form-check-input"
            aria-describedby="comment_like_description"
          >
          <label
            for="comment_like"
            class="form-check-label"
          >
            Comment likes
          </label>

          <small id="comment_like_description">
            Someone liked a comment you made
          </small>
        </div>

        <div class="form-check">
          <input
            id="team_join_request_received"
            v-model="formFields.team_join_request_received"
            type="checkbox"
            class="form-check-input"
            aria-describedby="team_join_request_received_description"
          >
          <label
            for="team_join_request_received"
            class="form-check-label"
          >
            Team join requests
          </label>

          <small id="team_join_request_received_description">
            Someone requested to join your team
          </small>
        </div>

        <div class="form-check">
          <input
            id="team_join_invitation_accepted"
            v-model="formFields.team_join_invitation_accepted"
            type="checkbox"
            class="form-check-input"
            aria-describedby="team_join_invitation_accepted_description"
          >
          <label
            for="team_join_invitation_accepted"
            class="form-check-label"
          >
            Team join request acceptances
          </label>

          <small id="team_join_invitation_accepted_description">
            Someone accepted your invitation to join your team
          </small>
        </div>

        <div class="form-group form-check">
          <input
            id="team_join_invitation_declined"
            v-model="formFields.team_join_invitation_declined"
            type="checkbox"
            class="form-check-input"
            aria-describedby="team_join_invitation_declined_description"
          >
          <label
            for="team_join_invitation_declined"
            class="form-check-label"
          >
            Team join request declinations
          </label>

          <small id="team_join_invitation_declined_description">
            Someone declined your invitation to join your team
          </small>
        </div>

        <button
          type="submit"
          class="btn btn-primary"
          :disabled="formSubmitting"
        >
          <template v-if="formSubmitting">
            Saving
            <spinner />
          </template>

          <template v-else>
            Save
          </template>
        </button>
      </form>
    </template>
  </template>

  <template v-else-if="initialDataStatus === 'error'">
    <alert variant="danger">
      An error occurred loading your data. Please check your connection and try
      again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadInitialData"
    >
      Try Again
    </button>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    formFields: {
      post_comment: '',
      post_like: '',
      comment_reply: '',
      comment_like: '',
      team_join_request_received: '',
      team_join_invitation_accepted: '',
      team_join_invitation_declined: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_notifications/',
      });

      if (responseData.status === 200) {
        const initialData = responseData.body;

        this.formFields.post_comment = initialData.post_comment;
        this.formFields.post_like = initialData.post_like;
        this.formFields.comment_reply = initialData.comment_reply;
        this.formFields.comment_like = initialData.comment_like;
        this.formFields.team_join_request_received = initialData.team_join_request_received;
        this.formFields.team_join_invitation_accepted = initialData.team_join_invitation_accepted;
        this.formFields.team_join_invitation_declined = initialData.team_join_invitation_declined;

        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'users/settings_notifications/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.formSuccess = true;
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style scoped>
  form {
    margin: 0 auto 1rem;
    width: 290px;
  }
</style>
