
import { defineComponent } from 'vue';
import SettingsAccount from '@/components/users/SettingsAccount.vue';
import SettingsImages from '@/components/users/SettingsImages.vue';
import SettingsIpg from '@/components/users/SettingsIpg.vue';
import SettingsNotifications from '@/components/users/SettingsNotifications.vue';
import SettingsSubscription from '@/components/users/SettingsSubscription.vue';
import SettingsThirdPartyAccounts from '@/components/users/SettingsThirdPartyAccounts.vue';

export default defineComponent({
  components: {
    SettingsAccount,
    SettingsImages,
    SettingsIpg,
    SettingsNotifications,
    SettingsSubscription,
    SettingsThirdPartyAccounts,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
});
